import { useLDClient, LDContext, useFlags } from 'launchdarkly-react-client-sdk';
import { merge } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { IUser } from 'src/types/User';

type LDFlags = {
  messageCount: number;
  commonPhrasesVersion: number;
  defaultDateRangeInterval: string;
  defaultDashboardDateRange: string;
  publicLinkEnabled: boolean;
  defaultEsAlias?: string;
  conversationAudioEnabled?: boolean;
  anomalyStatus: boolean;
};

export const useLDFlags = () => useFlags<LDFlags>();

export const useLaunchDakly = () => {
  const ldClient = useLDClient();
  const ldIdentify = async (context: LDContext) => camelcaseKeys(await ldClient.identify(context)) as Promise<LDFlags>;
  const identifyUser = (user: IUser, extra?: Partial<LDContext>) => {
    const context: LDContext = {
      kind: 'user',
      key: user.uuid,
      name: user.name,
      email: user.email,
      custom: { company: user.clients?.[0]?.name, subDomainName: window.location.hostname.split('.')[0] },
    };
    return ldIdentify(merge(context, extra));
  };
  const updateLDContext = (context: Partial<LDContext>) => ldIdentify(merge(ldClient.getContext(), context));
  return { identifyUser, updateLDContext };
};
