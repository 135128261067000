import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SOMETHING_WRONG } from '../utils/constants/common';
import { api } from '../api';
import { getUserDetails, ssoLoginApi } from 'src/api/User';
import { RootState } from '../store';
import { IAuthState } from './types/auth';
import { cloneDeep } from 'lodash';
import { createDiyTag, deleteDiyTag } from 'src/api/DiyTag';
import { setOrRemoveDashboardHomeThunk } from './dashboard';

export const fetchUser = createAsyncThunk('auth/fetchUser', () => getUserDetails());

export const ssoLogin = createAsyncThunk('auth/ssoLogin', ssoLoginApi);

export const verifyAndEnable2FA = createAsyncThunk<void, { totp: string }>('auth/verifyAndSet2FA', async ({ totp }) => {
  await api.verify2FAKey(totp);
  await api.set2FA(true);
});

const initialState: IAuthState = {
  isAuthenticated: false,
  is2FARequired: false,
  loginInitiated: false,
  loginSucceeded: false,
  loginFailed: false,
  userAPIStatus: 'idle',
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),
    setLoginSuccessful(state) {
      state.loginInitiated = false;
      state.loginFailed = false;
      state.loginFailureMessage = undefined;
      state.loginSucceeded = true;
      state.emailError = undefined;
    },
    submitUserSurvey(state) {
      if (!state.user) return;
      state.user.is_survey_submitted = true;
    },
    addOrUpdateDiyTagInClient(state, action: PayloadAction<Awaited<ReturnType<typeof createDiyTag>>>) {
      if (!state.user?.clients) return;
      const { es_alias: source, ...tagData } = action.payload;
      const clinentIndex = state.user.clients.findIndex(({ es_aliases }) => es_aliases && es_aliases.some(({ name }) => name === source));
      const clientEsalias = state.user.clients[clinentIndex].es_aliases;
      if (!clientEsalias) return;
      const esAliasIndex = clientEsalias.findIndex(({ name }) => name === source);
      let customTopics = cloneDeep(clientEsalias[esAliasIndex].custom_topics) || [];
      if (!customTopics.some((topic) => topic.id === tagData.id)) customTopics.push(tagData);
      else customTopics = customTopics.map((topic) => (topic.id === tagData.id ? tagData : topic));
      clientEsalias[esAliasIndex].custom_topics = customTopics;
    },
    deleteDiyTagInClient(state, action: PayloadAction<Awaited<ReturnType<typeof deleteDiyTag>>>) {
      if (!state.user?.clients) return;
      const { es_alias: source, ...tagData } = action.payload;
      const clinentIndex = state.user.clients.findIndex(({ es_aliases }) => es_aliases && es_aliases.some(({ name }) => name === source));
      const clientEsalias = state.user.clients[clinentIndex].es_aliases;
      if (!clientEsalias) return;
      const esAliasIndex = clientEsalias.findIndex(({ name }) => name === source);
      let customTopics = cloneDeep(clientEsalias[esAliasIndex].custom_topics) || [];
      customTopics = customTopics.filter((topic) => topic.id !== tagData.id);
      clientEsalias[esAliasIndex].custom_topics = customTopics;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.userAPIStatus = 'pending';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.userAPIStatus = 'fulfilled';
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loginInitiated = false;
        state.loginFailed = true;
        state.loginSucceeded = false;
        state.loginFailureMessage = action?.error?.message || SOMETHING_WRONG;
        state.userAPIStatus = 'rejected';
      })
      .addCase(ssoLogin.fulfilled, (state) => {
        state.loginInitiated = false;
        state.loginFailed = false;
        state.loginFailureMessage = undefined;
        state.is2FARequired = false;
      })
      .addCase(setOrRemoveDashboardHomeThunk.fulfilled, (state, action) => {
        const { dashboardId, global } = action.meta.arg;
        state.user.home_dashboards[global ? 'global' : 'user'] = dashboardId ? dashboardId.toString() : null;
      });
  },
});

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isAuthenticated', 'is2FARequired', 'tokens', 'user'],
};
export const { reset, setLoginSuccessful, submitUserSurvey, addOrUpdateDiyTagInClient } = AuthSlice.actions;

export default persistReducer(persistConfig, AuthSlice.reducer);

export const isUserAdmin = (state: RootState) => state.auth.user?.role === 'M' || state.auth.user?.role === 'A';

export const sourcesAvailableSelector = (state: RootState) => state.auth.user?.clients?.some(({ es_aliases }) => es_aliases?.length);

export const anamolySourceSelector = (state: RootState) =>
  state.auth.user?.clients
    ?.map(({ es_aliases }) => es_aliases)
    .flat()
    .find(({ enabled_anomaly_detection }) => enabled_anomaly_detection);
