import moment from 'moment';
import { CommonFilterState } from 'Slices/types/common';
import { store } from 'src/core/store';
import { IBookmarkFilter, IFilterData, IFilterGroup } from 'src/types/Bookmark';
import { FilterOperations, FilterRelations } from 'src/types/enums';
import { IFieldType, IFilterOption } from 'src/types/Filter';
import { IViewFilterData } from 'src/types/Views';
import { FILTER_TOPIC_MATCH_TYPES, FILTER_VIEW_METADATA_DEFAULT_OPTIONS } from 'Utils/constants/common';
import { CHART_COMPARATOR_TYPES } from 'Utils/enums/ChartComparatorTypes';
import { ADMIN_INDEX_SOURCE_TYPES, SURVEY_TYPES } from 'Utils/enums/SourceModelTypes';
import { IFilterDataRequest } from '../apiTypes/Filters';
import { DIMENSION_TYPES } from 'Utils/enums/chartDimensionsType';
import { Dimension, Metric } from 'src/types/User';
import { getUserTimezone } from 'Utils/dateOperations';

export const formatDateRange = (dateRange: { start: Date; end: Date; timezone?: string }, useLegacyDateFormat?: boolean) => {
  const range = {
    ...dateRange,
    start: moment(dateRange.start).format('YYYY-MM-DD'),
    end: moment(dateRange.end).format('YYYY-MM-DD'),
  };
  if (!useLegacyDateFormat) {
    range.start = range.start.concat('T00:00:00');
    range.end = range.end.concat('T23:59:59');
  }
  return range;
};

export const getInvervalObject = (
  chartIntervalBreakdown: CommonFilterState['chartIntervalBreakdown'],
  filters: Partial<CommonFilterState['filters']>,
  useLegacyDateFormat?: boolean
) => {
  const { dateRange, selectedDateField, previousDateRange } = filters;
  const interval = {
    breakdown: chartIntervalBreakdown ? chartIntervalBreakdown : 'day',
    dateField: { date_field: selectedDateField },
    range: dateRange ? formatDateRange(dateRange, useLegacyDateFormat) : undefined,
    previous_range: previousDateRange ? formatDateRange(previousDateRange, useLegacyDateFormat) : undefined,
    previous_interval: 'custom',
    timezone: getUserTimezone(),
  };
  return interval;
};

const hasInvalidValues = (values: string[]) => values.length === 0 || (values.length === 1 && values[0] === '');

export const hasInValidFilters = (filters: IBookmarkFilter) => {
  const { datasourceFilters } = filters;
  return Object.values(datasourceFilters).some((filterGroups) =>
    filterGroups.some((filterGroup) => filterGroup.conditionalFilters.some((conditionalFilter) => hasInvalidValues(conditionalFilter.values)))
  );
};

const removeInvalidFilterGroups = (filterGroups: IFilterGroup[]) =>
  filterGroups.filter((filterGroup) => filterGroup.conditionalFilters.length > 0 && !hasInvalidValues(filterGroup.conditionalFilters[0].values));

const removeInvalidConditionalFilters = (conditionalFilters: IFilterData[]) =>
  conditionalFilters.filter((conditionalFilter) => !hasInvalidValues(conditionalFilter.values));

export const transformFilters = (filters: IBookmarkFilter) =>
  Object.entries(filters.datasourceFilters).map(([dataSource, filterGroups]) => ({
    dataSource,
    logicalFilterGroups: removeInvalidFilterGroups(filterGroups).map((filterGroup) => ({
      conditionalFilterRelation: filterGroup.conditionalFilterRelation,
      previousFilterRelation: filters.previousFilterRelation,
      conditionalFilters: removeInvalidConditionalFilters(filterGroup.conditionalFilters),
    })),
  }));

const convertToSearchObject = (values: string[] | string) => {
  let selectedValues = [];
  if (typeof values === 'string') {
    selectedValues.push(values);
  } else if (Array.isArray(values)) {
    selectedValues = values;
  }
  return {
    type: 'should',
    values: selectedValues,
  };
};

export interface FormatFiltersV4Params {
  sentiment?: number;
  search?: string;
  diy_topic?: string;
  topic?: string;
  theme?: string[];
  dimension_values?: string;
  aspect?: string;
  chartComparator?: string;
  addTypeMetric?: boolean;
  dimension?: Dimension;
  metric?: Metric;
  useLegacyDateFormat?: boolean;
}

export type FormatFiltersV4State = Pick<
  Partial<CommonFilterState>,
  | 'filters'
  | 'chartIntervalBreakdown'
  | 'chartDimensionObject'
  | 'chartMetricObject'
  | 'selectedSourceClientData'
  | 'typeMetric'
  | 'bookmarkFilters'
  | 'chartDimension'
  | 'chartMetric'
>;

export const formatFiltersV4 = (params: FormatFiltersV4Params, state?: FormatFiltersV4State) => {
  const { sentiment, search, diy_topic, topic, theme, dimension_values, aspect, chartComparator, addTypeMetric, dimension, metric } = params;
  const { filter } = store.getState();
  const { filters, chartIntervalBreakdown, chartDimensionObject, chartMetricObject, selectedSourceClientData, typeMetric } = state ?? filter;
  const { source, isRestrictDimension } = filters;
  const dimensionName = dimension ? dimension.name : chartDimensionObject?.name;
  const metrics = [metric ? metric : chartMetricObject];
  if (addTypeMetric && SURVEY_TYPES.includes(selectedSourceClientData?.es_alias.type) && !!typeMetric && typeMetric.name !== chartMetricObject.name)
    metrics.push(typeMetric);
  return {
    source,
    conditions: {
      interval: getInvervalObject(chartIntervalBreakdown, filters, params.useLegacyDateFormat),
      dataSourceFilters: filters?.metadata?.length ? filters.metadata : undefined,
      match_topics: theme || topic || diy_topic ? FILTER_TOPIC_MATCH_TYPES.EXACT : FILTER_TOPIC_MATCH_TYPES.ALL,
      diy_topics:
        dimensionName === DIMENSION_TYPES.DIY_TAG && (diy_topic || topic || theme) ? [convertToSearchObject(diy_topic ?? topic ?? theme)] : undefined,
      search: search ? search : undefined,
      topics:
        dimensionName === DIMENSION_TYPES.SENTISUM_TAG && (topic || (theme && theme.length)) ? [convertToSearchObject(topic ?? theme)] : undefined,
      aspects: aspect ? [convertToSearchObject(aspect)] : undefined,
      dimension_values:
        (![DIMENSION_TYPES.SENTISUM_TAG, DIMENSION_TYPES.DIY_TAG].includes(dimensionName as DIMENSION_TYPES) && topic) || dimension_values
          ? [dimension_values || topic]
          : undefined,
      sentiment: sentiment > 0 ? sentiment : undefined,
      restrict_dimensions: filters?.metadata?.length ? isRestrictDimension : false,
    },
    dimension: dimension ?? chartDimensionObject,
    comparisons: chartComparator ? [chartComparator] : [CHART_COMPARATOR_TYPES.ABSOLUTE_TYPE, CHART_COMPARATOR_TYPES.PERCENT_TYPE],
    metrics,
  };
};

interface FormatFiltersV5Params extends FormatFiltersV4Params {
  filter_dimension?: {
    filter_value: string;
    dimension: Dimension;
  };
}

// only used for total_count api
export const formatFiltersV5 = (params: FormatFiltersV5Params, state?: FormatFiltersV4State) => {
  const {
    sentiment,
    search,
    diy_topic,
    topic,
    theme,
    dimension_values,
    aspect,
    chartComparator,
    addTypeMetric,
    dimension,
    metric,
    filter_dimension,
  } = params;
  const { filter } = store.getState();
  const { filters, chartIntervalBreakdown, chartDimensionObject, chartMetricObject, selectedSourceClientData, typeMetric } = state ?? filter;
  const { source, isRestrictDimension } = filters;
  const dimensionName = dimension ? dimension.name : chartDimensionObject?.name;
  const metrics = [metric ? metric : chartMetricObject];
  if (addTypeMetric && SURVEY_TYPES.includes(selectedSourceClientData?.es_alias.type) && !!typeMetric && typeMetric.name !== chartMetricObject.name)
    metrics.push(typeMetric);
  return {
    source,
    conditions: {
      interval: getInvervalObject(chartIntervalBreakdown, filters, params.useLegacyDateFormat),
      dataSourceFilters: filters?.metadata?.length ? filters.metadata : undefined,
      match_topics: theme || topic || diy_topic ? FILTER_TOPIC_MATCH_TYPES.EXACT : FILTER_TOPIC_MATCH_TYPES.ALL,
      diy_topics:
        dimensionName === DIMENSION_TYPES.DIY_TAG && (diy_topic || topic || theme?.length)
          ? [convertToSearchObject(diy_topic ?? topic ?? theme)]
          : undefined,
      search: search ? search : undefined,
      topics:
        dimensionName === DIMENSION_TYPES.SENTISUM_TAG && (topic || (theme && theme.length)) ? [convertToSearchObject(topic ?? theme)] : undefined,
      aspects: aspect ? [convertToSearchObject(aspect)] : undefined,
      dimension_values: dimension_values ? [dimension_values] : undefined,
      sentiment: sentiment > 0 ? sentiment : undefined,
      restrict_dimensions: filters?.metadata?.length ? isRestrictDimension : false,
      filter_dimension:
        dimensionName !== DIMENSION_TYPES.DIY_TAG && dimensionName !== DIMENSION_TYPES.SENTISUM_TAG && filter_dimension
          ? filter_dimension
          : undefined,
    },
    dimension: dimension ?? chartDimensionObject,
    comparisons: chartComparator ? [chartComparator] : [CHART_COMPARATOR_TYPES.ABSOLUTE_TYPE, CHART_COMPARATOR_TYPES.PERCENT_TYPE],
    metrics,
  };
};

export const transformViewToFilters = (view: IViewFilterData) => ({
  previousFilterRelation: view.dataSourceFilters?.[0]?.logicalFilterGroups?.[0]?.previousFilterRelation || FilterRelations.or,
  datasourceFilters:
    view.dataSourceFilters?.reduce((acc, { dataSource, logicalFilterGroups }) => {
      acc[dataSource] = logicalFilterGroups.map((logicalFilterGroup) => ({
        conditionalFilterRelation: logicalFilterGroup.conditionalFilterRelation,
        conditionalFilters: logicalFilterGroup.conditionalFilters,
      }));
      return acc;
    }, {}) ?? {},
});

type FilterFieldData = { all: boolean; options: IFilterOption[]; displayName: string; fieldType: IFieldType; loadValues: boolean };

export const getFilterFieldData: (data: IFilterData, source: string, state: CommonFilterState) => FilterFieldData = (
  filterData,
  dataSource,
  state
) => {
  const { filterdata, selectedSourceClientData } = state;
  const getAllDIYTopicList = () => selectedSourceClientData?.es_alias?.custom_topics?.map(({ name }) => ({ value: name, count: 0 })) ?? [];
  const commonFieldType: IFieldType = { field_type: 'text', applicable_operations: Object.values(FilterOperations) };
  switch (filterData.type) {
    case 'topic':
      return {
        all: true,
        options: filterdata.topics,
        displayName: FILTER_VIEW_METADATA_DEFAULT_OPTIONS.SENTISUM_TAG,
        fieldType: commonFieldType,
        loadValues: false,
      };
    case 'diyTopic':
      return {
        all: true,
        options: getAllDIYTopicList(),
        displayName: FILTER_VIEW_METADATA_DEFAULT_OPTIONS.DIY_TAG,
        fieldType: commonFieldType,
        loadValues: false,
      };
    case 'themes':
      return {
        all: true,
        options: filterdata.themes.map((theme) => ({ value: theme.name, count: 0 })),
        displayName: FILTER_VIEW_METADATA_DEFAULT_OPTIONS.Theme,
        fieldType: commonFieldType,
        loadValues: false,
      };
    default:
      const field = filterdata?.filters
        .find((field) => field.dataSource === dataSource)
        ?.metadataFilters.find(
          (metadataField) =>
            metadataField.parentField === filterData.type &&
            metadataField.searchField.concat(metadataField.keyword ? '.keyword' : '') === filterData.fieldName
        );
      return field ? { all: field.all, options: [], displayName: field.displayName, fieldType: field.fieldType, loadValues: field.all } : null;
  }
};

export const getFilterRequestData = (state: CommonFilterState) => {
  const date_field = state.filters.selectedDateField;
  const adminSourceType = state.selectedSourceClientData?.es_alias?.type;
  const sourceType = Object.entries(ADMIN_INDEX_SOURCE_TYPES).find(([_, value]) => value === adminSourceType)?.[0];
  const { source, dateRange } = state.filters;
  const filterdata: IFilterDataRequest = {
    source,
    conditions: {
      interval: {
        dateField: { date_field },
        range: {
          start: moment(dateRange.start).startOf('day').utc().toISOString(),
          end: moment(dateRange.end).endOf('day').utc().toISOString(),
        },
      },
    },
  };
  return { sourceType, filterdata } as const;
};
