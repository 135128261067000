import React, { FC, useRef } from 'react';
import { useIntersection, useTimeoutFn } from 'react-use';
import { TableBodyData } from './TableBodyData';
import { ADMIN_INDEX_SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { Topic } from 'src/api/apiTypes/Summary';
import { IChartComparator } from 'src/types/Filter';
import { IApiCallStatus } from 'src/types/Globals';
import { Metric, Dimension } from 'src/types/User';

export interface TableBodyProps {
  drivers: Topic[];
  chartMetricObject: Metric;
  chartComparator: IChartComparator;
  chartDimensionObject: Dimension;
  sourceType: ADMIN_INDEX_SOURCE_TYPES;
  status: IApiCallStatus;
  className?: string;
  openLinkInDiscoverPage?: (path) => void;
  lazyLoadDisabled?: boolean;
  animationDisabled?: boolean;
}
export const TableBody: FC<TableBodyProps> = ({ drivers, ...props }) => {
  const [animationDisabled, setAnimationDisabled] = React.useState(props.animationDisabled);
  useTimeoutFn(() => setAnimationDisabled(true), 1000);
  const intersectionRef = useRef<HTMLTableSectionElement>();
  const intersection = useIntersection(intersectionRef, { root: null, rootMargin: '0px', threshold: 0 });
  return (
    <tbody ref={props.lazyLoadDisabled ? null : intersectionRef}>
      {drivers.slice(0, intersection?.isIntersecting || props.lazyLoadDisabled ? drivers.length : 5).map((driver, index) => (
        <TableBodyData
          {...props}
          driver={driver}
          key={index}
          lazyLoadDisabled={props.lazyLoadDisabled || index < 5}
          animationDisabled={animationDisabled}
        />
      ))}
    </tbody>
  );
};
