import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import * as Select from 'src/components/Select';
import { updateChartDimensionType } from 'Slices/filter';
import { FilterButton } from './FilterButton';

export const DimensionDropdown = () => {
  const disaptch = useAppDispatch();
  const dimensions = useAppSelector((state) => state.filter.selectedSourceClientData.es_alias.dimensions);
  const dimension = useAppSelector((state) => state.filter.chartDimensionObject);
  const dimensionOptions = useMemo(() => dimensions.map(({ name, display_name }) => ({ value: name, label: display_name })), [dimensions]);
  const selectDimension = useCallback(
    (value: string) => disaptch(updateChartDimensionType(dimensions.find(({ name }) => name === value).name)),
    [dimensions]
  );
  if (dimensionOptions.length < 2) return <></>;
  return (
    <div className="relative">
      <Select.Root value={dimension.name} onValueChange={selectDimension}>
        <Select.Trigger asChild>
          <FilterButton>Dimension: {dimension.display_name}</FilterButton>
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={dimensionOptions} className="min-w-40" />
      </Select.Root>
    </div>
  );
};
