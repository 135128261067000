import { cloneElement, createContext, FC, PropsWithChildren, useCallback, useContext, useState, lazy, Suspense, useReducer } from 'react';
import * as NestedDialog from 'src/components/Dialog/NestedDialog';
import { ScreenShotBase } from 'src/ui/containers/ScreenShotBases';
import type { ExportAsPngData, ExportAsPngState } from './types';
import { canCopyImagesToClipboard, exportAsPng } from './utils';

const ConfirmPngExport = lazy(() => import('./ConfirmPngExport'));

export type ExportAsPngContextType = {
  downloadAsPng: (data: ExportAsPngData) => void;
  canCopyImagesToClipboard: boolean;
} | null;

const ExportAsPngContext = createContext<ExportAsPngContextType>(null);

export const ExportAsPngContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [exportState, setExportState] = useReducer(
    (state: ExportAsPngState, action: Partial<ExportAsPngState> | 'reset') => (action === 'reset' ? null : { ...state, ...action }),
    null
  );
  const downloadAsPng = useCallback((data: ExportAsPngData) => setExportState({ ...data, showConfirmModal: true, rowCount: data.rowCount ?? 0 }), []);

  const continueExport = useCallback(async () => {
    setExportState({ processing: true });
    exportAsPng(exportState.type, exportState.fileName);
    setExportState('reset');
  }, [exportState]);

  return (
    <ExportAsPngContext.Provider value={{ downloadAsPng, canCopyImagesToClipboard }}>
      {exportState?.element && (
        <NestedDialog.Root open>
          <NestedDialog.NestedDialogContent isOpen addPortal id="screenshot-element" className="opacity-0">
            <ScreenShotBase {...exportState.baseProps}>{cloneElement(exportState.element, { rowCount: exportState.rowCount })}</ScreenShotBase>
          </NestedDialog.NestedDialogContent>
        </NestedDialog.Root>
      )}
      {exportState?.showConfirmModal && (
        <Suspense fallback={<></>}>
          <ConfirmPngExport continueExport={continueExport} exportState={exportState} setExportState={setExportState} />
        </Suspense>
      )}
      {children}
    </ExportAsPngContext.Provider>
  );
};

export default ExportAsPngContext;
export const useExportAsPng = () => useContext(ExportAsPngContext);
