import { withProfiler } from '@sentry/react';
import { NavigationSidebar } from './NavigationSidebar';
import { Outlet } from 'src/components/Route';
import { PropsWithChildren } from 'react';

export const DashboardLayoutComponent: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="flex h-full bg-gray-650">
      <NavigationSidebar />
      <div>
        <div className="fixed w-full bg-gray-100">
          <div className="flex h-screen max-w-[calc(100vw-4rem)] flex-col">
            <Outlet />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DashboardLayout = withProfiler(DashboardLayoutComponent, { name: 'DashboardLayout' });
