import React, { FC, ReactNode, useCallback } from 'react';
import { FormField } from 'src/components/Form';
import { TagInputProps, TagsInput } from './TagsInput';
import { cn } from 'Utils/TailwindUtils';
import { useController, useFormContext } from 'react-hook-form';

interface FormTagsInputProps extends Pick<TagInputProps, 'className' | 'inputProps'> {
  label: ReactNode;
  name: string;
  help?: string;
}

export const FormTagsInput: FC<FormTagsInputProps> = ({ name, label, className, help, inputProps }) => {
  const context = useFormContext();
  const controller = useController({ name, control: context.control });
  const inputPropsWithValidation = { ...inputProps, onBlur: () => context.trigger(name) };
  const onChange = useCallback((value: string[]) => {
    controller.field.onChange(value);
    window.requestAnimationFrame(() => context.trigger(name));
  }, []);
  return (
    <FormField label={label} help={help} name={name ?? ''}>
      <TagsInput
        className={cn(['rounded-lg border border-grey-300 bg-white placeholder:text-gray-600'], className)}
        value={controller.field.value ?? []}
        onChange={onChange}
        addKeys={[',', 'Enter', 'Tab']}
        inputProps={inputPropsWithValidation}
        ref={controller.field.ref}
      />
    </FormField>
  );
};
