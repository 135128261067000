import { Navigate, Routes, Route } from 'react-router-dom';
import * as Pages from 'Pages';
import { useAppSelector } from './core/store';
import { AuthLayout, InternalRoute, Layout, ProtectedLayout } from './components/Route';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { PageSpinner } from './components/Spinner';
import { DashboardLayout } from './ui/containers';
import { sourcesAvailableSelector } from 'Slices/auth';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = () => {
  const clientsLength = useAppSelector((state) => state.auth?.user?.clients?.length);
  const sourceAvailable = useAppSelector(sourcesAvailableSelector);

  return (
    <Suspense fallback={<PageSpinner />}>
      <SentryRoutes>
        <Route path="/onboard" element={<AuthLayout title="Onboard" component={Pages.OnboardingPage} />} />
        <Route path="/login" element={<AuthLayout title="Login" component={Pages.LoginPage} />} />
        <Route path="/forgotpassword" element={<AuthLayout title="Forgot Password" component={Pages.ForgotPasswordPage} />} />
        <Route path="/confirm" element={<AuthLayout title="Confirm" component={Pages.ConfirmPage} />} />
        <Route path="/verify-email" element={<AuthLayout title="Verify Email" component={Pages.VerifyEmail} />} />
        <Route path="/email-preview/:id" element={<Layout responsiveView={false} title="Email Preview" component={Pages.EmailPreview} />} />
        <Route path="/unsubscribe/digest" element={<Layout title="Unsubscribe Digest" component={Pages.UnsubscribeDigest} />} />
        {clientsLength ? (
          <>
            <Route path="/oauth/zendesk" element={<ProtectedLayout component={Pages.ZendeskSupportOauthVerifierPage} />} />
            <Route path="/oauth/zendesk-chat" element={<ProtectedLayout component={Pages.ZendeskChatOauthVerifierPage} />} />
            <Route path="/oauth/survey-monkey" element={<ProtectedLayout component={Pages.SurveyMonkeyOauthVerifierPage} />} />
            <Route path="/oauth/gorgias" element={<ProtectedLayout component={Pages.GorgiasOauthVerifierPage} />} />
            <Route path="/settings/integrations/gorgias/install" element={<ProtectedLayout component={Pages.GorgiasAppInstall} />} />
            <Route path="/oauth/intercom" element={<ProtectedLayout component={Pages.IntercomOauthVerifierPage} />} />
            <Route path="/oauth/help-scout" element={<ProtectedLayout component={Pages.HelpScoutOAuthVerifierPage} />} />
            <Route path="/oauth/typeform" element={<ProtectedLayout component={Pages.TypeformOauthVerifierPage} />} />
            <Route element={<ProtectedLayout component={DashboardLayout} />}>
              {sourceAvailable ? (
                <>
                  <Route path="/" element={<InternalRoute title="Home" component={Pages.HomePage} />}>
                    <Route path=":reportId?" element={<InternalRoute title="Dashboard" component={Pages.DashboardContent} />} />
                  </Route>
                  <Route path="/discover" element={<InternalRoute title="Discover" component={Pages.DiscoverPage} />} />
                  <Route path="/dashboard/" element={<InternalRoute title="Dashboard" component={Pages.DashboardPage} />}>
                    <Route path=":id/:reportId?" element={<InternalRoute title="Dashboard" component={Pages.DashboardContent} />} />
                  </Route>
                  <Route path="query/report/:reportId" element={<InternalRoute title="Reports Query" component={Pages.QueryPage} />} />
                </>
              ) : (
                <Route path="/" element={<InternalRoute title="Home" component={Pages.NoSourceClientPage} />} />
              )}
              <Route path="/settings/" element={<InternalRoute title="Settings" component={Pages.SettingsPage} />}>
                <Route path="" element={<Navigate to="integrations" />} />
                <Route path="home" element={<InternalRoute title="Default Homepage" component={Pages.SetDefaultHomePage} />} />
                <Route path="team" element={<InternalRoute title="Team Members" component={Pages.Team} />} />
                <Route path="digests" element={<InternalRoute title="Email Digests" component={Pages.Digest} />} />
                <Route path="integrations" element={<InternalRoute title="Integrations" component={Pages.IntegrationPage} />}>
                  <Route path=":integration/success" element={<Pages.IntegrationSucceeded />} />
                </Route>
                <Route path="theme" element={<InternalRoute title="Theme Management" component={Pages.ThemesPage} />} />
              </Route>
              <Route path="/alerts/" element={<InternalRoute title="Alerts" component={Pages.AlertsPage} />}>
                <Route path="" element={<InternalRoute title="Alerts" component={Pages.YourAlerts} />}>
                  <Route path=":id" element={<InternalRoute title="Investigate Alert" component={Pages.InvestigateAlert} />} />
                </Route>
                <Route path="configuration" element={<InternalRoute title="Alert Configuration" component={Pages.AlertSetup} />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <Route path="*" element={<ProtectedLayout title="Dashboard" component={Pages.NoSourcesPage} />} />
        )}
      </SentryRoutes>
    </Suspense>
  );
};

export default AppRouter;
