import React, { useMemo } from 'react';
import { LANG_TYPES, TEXT_TYPES } from 'Utils/constants/languages';
import { CHART_COMPARATOR_TYPES_ENUM } from 'Enums/ChartComparatorTypes';
import { SOURCE_TYPES, SURVEY_TYPES } from 'Enums/SourceModelTypes';
import { cn } from 'Utils/TailwindUtils';
import { NoDataError } from './NoDataError';
import { SummaryCardLoader } from './SummaryCardLoader';
import { TableHeader } from './TableHeader';
import { TableBody, TableBodyProps } from './TableBody';
import { SURVEY_DRIVERS_HEADER_NAME } from 'Enums/SummaryDriverTypes';

export const SummaryTable: React.FC<TableBodyProps> = (props) => {
  const { drivers, chartMetricObject, chartComparator, chartDimensionObject, sourceType, status, className } = props;
  const isSurveyType = useMemo(() => SURVEY_TYPES.includes(sourceType), [sourceType]);
  if (status === 'pending') return <SummaryCardLoader />;
  if (status === 'rejected' || !drivers || !drivers.length) return <NoDataError />;
  return (
    <>
      <table className={cn('w-full table-fixed text-center text-sm', className)}>
        <thead className="sticky top-0 z-10 rounded-xl bg-gray-50 text-xs capitalize text-grey-500">
          <tr>
            <TableHeader className="text-left">{chartDimensionObject?.display_name}</TableHeader>
            <TableHeader>{isSurveyType ? SURVEY_DRIVERS_HEADER_NAME[chartMetricObject.name] : chartMetricObject.display_name}</TableHeader>
            <TableHeader>{CHART_COMPARATOR_TYPES_ENUM[chartComparator][LANG_TYPES.EN][TEXT_TYPES.LONG]}</TableHeader>
            {SOURCE_TYPES.SURVEY_TYPE === chartMetricObject.name && <TableHeader>No. of Responses</TableHeader>}
          </tr>
        </thead>
        <TableBody {...props} />
      </table>
    </>
  );
};
