import { ForwardedRef, forwardRef } from 'react';
import { IOption } from '../../types';
import { cn } from 'Utils/TailwindUtils';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useMatchedOptions } from '../../Hooks';

interface RadixDropdownOptionProps<T> {
  options: IOption<T>[];
  searchText: string;
  className?: string;
  onItemClick: (value: T) => void;
  optionClassName?: string;
}

const RadixDropdownOptionFunction = <T,>(
  { options, onItemClick, className, searchText, optionClassName }: RadixDropdownOptionProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const matchedOptions = useMatchedOptions(options, searchText);
  return (
    <div className={cn(className, 'flex flex-col overflow-y-auto')}>
      {matchedOptions.map((option) => (
        <DropdownMenuPrimitive.Item key={`item-${option.label}`} asChild>
          <button
            className={cn(
              'rounded-md px-5 py-2 text-left text-sm focus-within:bg-sky-1000 hover:bg-sky-1000 disabled:text-blue-450',
              optionClassName
            )}
            disabled={option.disabled}
            onClick={() => onItemClick(option.value)}
          >
            {option.label}
          </button>
        </DropdownMenuPrimitive.Item>
      ))}
    </div>
  );
};
export const RadixDropdownOption = forwardRef(RadixDropdownOptionFunction);
