import React, { ForwardedRef } from 'react';
import BaseTagInput, { ReactTagsInputProps } from 'react-tagsinput';
import { cn } from 'Utils/TailwindUtils';
import { useCss } from 'react-use';
import { renderTag } from './Tag';
import { renderTagsLayout } from './TagsLayout';

export type TagsInputRefType = ForwardedRef<HTMLInputElement>;
export type TagInputProps = ReactTagsInputProps<string>;

const TagsInputComponent = ({ className, inputProps = {}, ...props }: TagInputProps, ref: TagsInputRefType) => {
  const inputClass = useCss({
    input: {
      flexGrow: 1,
      '&:focus-visible': {
        outline: 'none',
      },
    },
  });

  return (
    <BaseTagInput
      pasteSplit={(data) => data.split(',')}
      className={cn('text-xs font-semibold text-blue-1100 placeholder:text-gray-850', inputClass, className)}
      addKeys={[',']}
      addOnBlur
      addOnPaste
      inputProps={{ ...inputProps, ref }}
      renderTag={renderTag}
      validationRegex={/^(?!\s*$).+/}
      renderLayout={renderTagsLayout}
      {...props}
    />
  );
};

export const TagsInput = React.forwardRef(TagsInputComponent);
