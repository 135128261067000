import { DropButton } from 'grommet';
import { createElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { DropdownLabel } from '../DropdownLabel';
import { IDropdownMenuBase, DropdownMenu } from '../DropdownMenu';

const StyledDropButton = styled(DropButton)({
  borderRadius: '5px',
  border: '0px solid',
  padding: '4px',
  '&:hover': { boxShadow: 'none !important' },
});

interface IIconDropdownProps<T> extends IDropdownMenuBase<T> {
  icon?: JSX.Element;
  label?: typeof DropdownLabel;
}

const IconDropdown = <T,>({ icon, selected, label = DropdownLabel, ...rest }: IIconDropdownProps<T>) => {
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  return (
    <StyledDropButton
      dropAlign={{ top: 'bottom', left: 'left' }}
      icon={icon}
      size="small"
      gap="xsmall"
      dropContent={<DropdownMenu collapseMenu={onClose} selected={selected} {...rest} />}
      label={createElement(label, { label: selected?.label })}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default IconDropdown;
