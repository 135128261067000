import { Box } from 'grommet';
import { useAppDispatch, useAppSelector } from 'src/core/store';

import { DataExportTypeSelectorComponent } from './DataExportTypeSelectorComponent';
import { EXPORT_DATA_TYPE } from './DataExportEnums';
import { DataExportConfigurationComponent } from './DataExportConfigurationComponent';
import { useEffect } from 'react';
import { fetchConversationsMetadataFields } from 'Slices/timeseries';
export const DataExportPopupContainer = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.filter.filters);
  const { fetchConversationsMetadataFieldsSource, fetchConversationsMetadataFieldsStatus } = useAppSelector((state) => state.timeseries);

  const { dataExportType, dataExportStep } = useAppSelector((state) => state.dataexport);
  const showDataExportContainer = () => {
    if (dataExportStep === 0) {
      return <DataExportTypeSelectorComponent />;
    } else {
      if (dataExportType === EXPORT_DATA_TYPE.CONVERSATION_DATA) {
        return <DataExportConfigurationComponent />;
      } else if (dataExportType === EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS) {
      }
    }
    return <DataExportTypeSelectorComponent />;
  };
  useEffect(() => {
    if (dataExportType === EXPORT_DATA_TYPE.CONVERSATION_DATA && dataExportStep === 1) {
      if (filters?.source && (fetchConversationsMetadataFieldsStatus !== 'fulfilled' || filters?.source !== fetchConversationsMetadataFieldsSource)) {
        dispatch(fetchConversationsMetadataFields({ source: filters?.source }));
      }
    }
  }, [dataExportType]);
  return (
    <Box
      pad={'small'}
      gap={'small'}
      width={{ minWidth: '420px', maxWidth: '75%', width: '620px' }}
      overflow={{ vertical: 'auto', horizontal: 'hidden' }}
    >
      {showDataExportContainer()}
    </Box>
  );
};
