import React, { ForwardedRef, useState, useEffect } from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from 'Utils/TailwindUtils';
import { CheckBox, TextInputGray } from 'src/components/Input';
import { IOption } from 'src/components/Dropdown/types';
import { useMatchedOptions, useSearchText, useSelectAll } from 'src/components/Dropdown/Hooks';
import { Button } from 'src/components/Button';

interface RadixMultipleSelectWithApplyProps<T> extends DropdownPrimitive.DropdownMenuContentProps {
  options: IOption<T>[];
  searchPlaceholder?: string;
  selectedOptions: T[];
  menuOpen: boolean;
  onApply?: (values: T[]) => void;
  applyLabel?: string;
}

const ValuesDropDownFunction = <T,>(
  { options, searchPlaceholder, selectedOptions, menuOpen, onApply, applyLabel, ...rest }: RadixMultipleSelectWithApplyProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [searchString, setSearchString] = useSearchText(menuOpen);
  const matchedOptions = useMatchedOptions(options, searchString, selectedOptions);
  const [selectedValues, setSelectedValues] = useState(selectedOptions);
  const { allSelected, someSelected, handleSelectAll } = useSelectAll(matchedOptions, selectedValues, setSelectedValues);
  const handleSelect = (value: T) =>
    selectedValues.includes(value)
      ? setSelectedValues(selectedValues.filter((selectedValue) => selectedValue !== value))
      : setSelectedValues([...selectedValues, value]);
  const handleApply = () => onApply?.(selectedValues);
  useEffect(() => menuOpen && setSelectedValues(selectedOptions), [menuOpen]);
  return (
    <DropdownPrimitive.Content ref={ref} className={cn('radix-dropdown-content flex min-w-60 flex-col p-2')} align="start" {...rest}>
      <TextInputGray value={searchString} onChange={(e) => setSearchString(e.target.value)} placeholder={searchPlaceholder ?? 'Search'} autoFocus />
      <div className="flex max-h-72 flex-col gap-2 overflow-y-auto">
        <div className="hover:bg-sky-1000">
          <CheckBox
            label={<span className="text-sm">Select All</span>}
            checked={allSelected}
            indeterminate={someSelected}
            onChange={handleSelectAll}
            disabled={matchedOptions.length === 0}
            name="Select All"
            className="my-3 ml-3"
          />
        </div>
        {matchedOptions.map((option) => (
          <div className="hover:bg-sky-1000" key={option.label}>
            <CheckBox
              label={<span className="text-sm capitalize">{option.label}</span>}
              key={option.label}
              checked={selectedValues.includes(option.value)}
              onChange={() => handleSelect(option.value)}
              name={option.label}
              className="my-3 ml-3"
            />
          </div>
        ))}
      </div>
      <Button variant="primary" label={applyLabel ?? 'Apply'} onClick={handleApply} disabled={selectedValues.length === 0} />
    </DropdownPrimitive.Content>
  );
};

export const RadixMultipleSelectWithApply = React.forwardRef(ValuesDropDownFunction);
