export enum SummaryTableVisualization {
  BiggestChanges = 'BC',
  Highest = 'H',
  Lowest = 'L',
  TopPositive = 'TP',
  TopNegative = 'TN',
  TopIncrease = 'TI',
  TopDecrease = 'TD',
}

export type SelectedVisualizationType = 'T' | 'VBG' | 'HBG' | 'LG' | '';

export interface IShowDrivers {
  show: boolean;
  driverCount: number;
}
