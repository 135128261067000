import React, { createElement, ForwardedRef } from 'react';
import { ButtonProps } from './types';
import { cn } from 'Utils/TailwindUtils';

const BlockButtonSmallComponent = ({ label, variant, icon, className, children, ...rest }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <button
      className={cn(
        'flex items-center gap-1 rounded-md px-2 py-1.5 text-xs',
        {
          'bg-red-1000/10 hover:bg-red-1000/20': variant === 'danger',
          'bg-gray-1200/10 hover:bg-gray-1200/20': variant === 'secondary',
          'bg-blue-750/10 text-blue-750 hover:bg-blue-750/20': variant === 'info',
          'border text-blue-1100 hover:bg-indigo-50': variant === 'primary',
        },
        className
      )}
      ref={ref}
      {...rest}
    >
      {icon && createElement(icon, { size: '16px', className: 'shrink-0' })}
      {label && <span>{label}</span>}
      {children}
    </button>
  );
};

export const BlockButtonSmall = React.forwardRef(BlockButtonSmallComponent);
