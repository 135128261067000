import { createElement, forwardRef } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { IconOption } from '../../types';
import { cn } from 'Utils/TailwindUtils';
export interface RadixIconDropdownMenuProps extends DropdownMenuPrimitive.DropdownMenuContentProps {
  options: IconOption[];
}

export const RadixIconDropdownMenu = forwardRef<HTMLDivElement, RadixIconDropdownMenuProps>(({ options, className, ...rest }, ref) => {
  return (
    <DropdownMenuPrimitive.Content
      align="start"
      ref={ref}
      className={cn('radix-dropdown-content flex min-w-[10rem] flex-col p-2', className)}
      {...rest}
    >
      {options.map(({ label, icon, onClick, iconProps, iconClassName, className, ...rest }, index) => (
        <DropdownMenuPrimitive.Item
          key={index}
          onClick={onClick}
          {...rest}
          className={cn('flex items-center gap-3 rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000', className)}
          role="button"
        >
          {createElement(icon, { size: 18, className: iconClassName, ...iconProps })}
          <span>{label}</span>
        </DropdownMenuPrimitive.Item>
      ))}
    </DropdownMenuPrimitive.Content>
  );
});
