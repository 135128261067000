import auth from './auth';
import onboard from './onboard';
import timeseries from './timeseries';
import filter from './filter';
import summary from './summary';
import analytics from './analytics';
import oauth from './oauth';
import filterbookmarks from './filterbookmarks';
import dataexport from './dataExport';
import dashboard from './dashboard';
import editReport from './editReport';
const reducer = {
  auth,
  onboard,
  timeseries,
  filter,
  summary,
  analytics,
  oauth,
  filterbookmarks,
  dataexport,
  dashboard,
  editReport,
};

export default reducer;
