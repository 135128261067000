import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/browser';
import { Auth } from '@aws-amplify/auth';
import { store } from 'src/core/store';
import { logout } from 'Utils/auth';
export const PREVIOUS_DEPENDS_ON_KEY = 'previousDependsOn';
const abortControllers: { [key: string]: AbortController } = {};
export const previousDependsOn: { [key: string]: string[] } = JSON.parse(localStorage.getItem(PREVIOUS_DEPENDS_ON_KEY)) ?? {};

export const getAccessToken = async () => {
  const user = store.getState().auth.user;
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (err) {
    if (user) logout();
    return undefined;
  }
};

const ignoreApiMessages = [
  'Theme Topic Mapping does not exist.',
  'User is not confirmed.',
  'Incorrect username or password.',
  'User does not exist.',
];

export const getAdminClientInstance = (key: string, ...deps: string[]) => {
  abortControllers[key]?.abort();
  abortControllers[key] = new AbortController();
  const client = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_ENDPOINT,
    timeout: 30000,
    responseType: 'json',
    signal: abortControllers[key].signal,
  });
  return addInterceptors(client, key, deps);
};

export const getAdminClientInstanceWthToken = (key: string, token: string, ...deps: string[]) => {
  const client = getAdminClientInstance(key, ...deps);
  client.defaults.headers.Authorization = `Bearer ${token}`;
  return client;
};

export const getCdnClientInstance = (key: string, ...deps: string[]) => {
  abortControllers[key]?.abort();
  abortControllers[key] = new AbortController();
  const client = axios.create({
    baseURL: process.env.REACT_APP_CDN_ENDPOINT,
    timeout: 30000,
    responseType: 'json',
    signal: abortControllers[key].signal,
  });
  return client;
};

export const getDataApiClientInstance = (key: string, ...deps: string[]) => {
  abortControllers[key]?.abort();
  abortControllers[key] = new AbortController();
  const client = axios.create({
    baseURL: process.env.REACT_APP_CORE_API_ENDPOINT,
    timeout: 30000,
    responseType: 'json',
    signal: abortControllers[key].signal,
  });
  return addInterceptors(client, key, deps);
};

export const setSentryContext = (config: AxiosRequestConfig, key?: string) => ({
  key: key ?? config.url,
  extra: {
    'Request(URL)': config.url,
    'Request(Method)': config.method,
    'Request(Params)': config.params,
    'Request(Body)': config.data,
  },
});

export const sendHttpSentryError = (sentryContext: ReturnType<typeof setSentryContext>, error: AxiosError) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(sentryContext.key);
    scope.setExtras(sentryContext.extra);
    scope.setExtra('Response(Status)', error.response?.status);
    scope.setExtra('Response(Data)', error.response?.data);
    if (ignoreApiMessages.includes(error?.response?.data?.['message']) || axios.isCancel(error)) return;
    Sentry.captureException(error);
  });
};

const addInterceptors = (client: AxiosInstance, key: string, deps: string[]) => {
  let sentryContext: ReturnType<typeof setSentryContext>;
  client.interceptors.request.use(
    async (config) => {
      const accessToken = await getAccessToken();
      sentryContext = setSentryContext(config, key);
      if (accessToken) config.headers.set('Authorization', `Bearer ${accessToken}`);
      return config;
    },
    (error) => {
      sendHttpSentryError(sentryContext, error);
      return Promise.reject(error);
    }
  );
  client.interceptors.response.use(
    (response) => {
      previousDependsOn[key] = deps;
      localStorage.setItem(PREVIOUS_DEPENDS_ON_KEY, JSON.stringify(previousDependsOn));
      return { ...response, config: undefined };
    },
    (error) => {
      sendHttpSentryError(sentryContext, error);
      return Promise.reject(error);
    }
  );
  return client;
};

export const cancelApiRequest = (key: string) => {
  abortControllers[key]?.abort();
};
