import { FC, PropsWithChildren, useRef } from 'react';
import { useScroll } from 'react-use';
import { useAppSelector } from 'src/core/store';
import { SourcesDropdown } from './SourcesDropdown';
import { FilterContainer, FilterContainerProps } from './FilterContainer';
import { NoSourceCard } from './NoSourceCard';
import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';

export const DashboardFiltersContainer: FC<PropsWithChildren<FilterContainerProps>> = ({ children, ...rest }) => {
  const srollContainerRef = useRef<HTMLDivElement>(null);
  const { y } = useScroll(srollContainerRef);
  const { fetchAvailbleDateRangeBoundsError, filters } = useAppSelector((state) => state.filter);
  return (
    <>
      {filters?.source ? <SourcesDropdown /> : <BarSkeleton width="8rem" />}
      {!fetchAvailbleDateRangeBoundsError ? (
        <>
          <FilterContainer {...rest} yScroll={y} />
          <div className="w-full grow overflow-auto border-t pb-16" ref={srollContainerRef}>
            <main className="flex min-h-max flex-col gap-1 px-3 pt-3">{children}</main>
          </div>
        </>
      ) : (
        <NoSourceCard />
      )}
    </>
  );
};
