import React from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { UserInvite } from 'src/ui/containers/UserInvite';

export const UserInviteButton = () => {
  return (
    <>
      <UserInvite>
        <button className="flex rounded-full p-2 hover:bg-gray-650" data-tooltip-content="Invite People" data-tooltip-id="navigation-sidebar-tooltip">
          <FiUserPlus />
        </button>
      </UserInvite>
    </>
  );
};
