import { getAllDashboards } from 'Slices/dashboard';
import { store } from '../store';

export const getFilterEventCommonProps = () => {
  if (store) {
    const storeData = store.getState();
    if (storeData) {
      const dateRange = storeData?.filter?.filters?.dateRange;
      const previousDateRange = storeData?.filter?.filters?.previousDateRange;
      const data = {
        source: storeData?.filter?.filters?.source ?? '',
        dateRangeStart: dateRange?.start?.toISOString?.(),
        dateRangeEnd: dateRange?.end?.toISOString?.(),
        viewName: storeData?.filterbookmarks?.selectedView?.name,
        theme: storeData?.filter?.selectedTheme ? storeData.filter?.selectedTheme : 'all',
        topic: storeData?.filter?.selectedTopic,
        aspect: storeData?.filter?.selectedAspect,
        chartMetric: storeData?.filter?.chartMetric,
        chartComparator: storeData?.filter?.chartComparator,
        chartIntervalBreakdown: storeData?.filter?.chartIntervalBreakdown,
        chartPreviousComparisonInterval: `${previousDateRange?.start?.toISOString?.()} - ${previousDateRange?.end?.toISOString?.()}`,
        searchString: storeData?.filter?.searchString,
      };
      return data;
    }
  }
  return {
    source: '',
    dateRangeStart: '',
    dateRangeEnd: '',
    viewName: '',
    tagView: '',
    theme: '',
    topic: '',
    aspect: '',
    chartMetric: '',
    chartComparator: '',
    chartIntervalBreakdown: '',
    chartPreviousComparisonInterval: '',
  };
};

export const getDashboardEventCommonProps = () => {
  if (!store) return {};
  const storeData = store.getState();
  const url = window.location.pathname;
  const selectedDashboardId = parseInt(url.substring(url.lastIndexOf('/') + 1));
  return {
    dashboardId: selectedDashboardId,
    dashboardName: getAllDashboards(storeData).find((e) => e.id === selectedDashboardId)?.name,
  };
};

export const getReportEventCommonProps = () => {
  if (store) {
    const storeData = store.getState();
    if (storeData) {
      const dateRange = storeData?.editReport?.filters?.dateRange;
      const previousDateRange = storeData?.editReport?.filters?.previousDateRange;
      const data = {
        reportId: storeData?.editReport?.id,
        reportName: storeData?.editReport?.name,
        dateRangeStart: dateRange?.start?.toISOString?.(),
        dateRangeEnd: dateRange?.end?.toISOString?.(),
        chartPreviousComparisonInterval: `${previousDateRange?.start?.toISOString?.()} - ${previousDateRange?.end?.toISOString?.()}`,
        reportType: storeData?.editReport?.visualizationType === 'overTime' ? 'LG' : storeData?.editReport?.tableVisualization,
        visualizationType: storeData?.editReport?.selectedVisualizationType,
      };
      return data;
    }
  }
  return {
    reportId: '',
    reportName: '',
    dateRangeStart: '',
    dateRangeEnd: '',
    chartPreviousComparisonInterval: '',
  };
};
