import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import * as Select from 'src/components/Select';
import { getBreakDownIntevals } from 'Utils/constants/BreakDownOptions';
import { INTERVAL_BREAKDOWN_TYPES } from 'Utils/enums/chartIntervalBreakdownTypes';
import { updateChartIntervalBreakdownSelector } from 'Slices/filter';
import { FilterButton } from './FilterButton';

export const IntervalDropdown = () => {
  const dispatch = useAppDispatch();
  const chartIntervalBreakdown = useAppSelector((state) => state.filter.chartIntervalBreakdown);
  const { dateRange } = useAppSelector((state) => state.filter.filters);
  const options = useMemo(() => getBreakDownIntevals(dateRange), [dateRange]);
  const onIntervelBreakdownSelect = useCallback((selected: INTERVAL_BREAKDOWN_TYPES) => dispatch(updateChartIntervalBreakdownSelector(selected)), []);
  return (
    <div className="relative">
      <Select.Root value={chartIntervalBreakdown} onValueChange={onIntervelBreakdownSelect}>
        <Select.Trigger asChild>
          <FilterButton>Interval: {options.find(({ value }) => value === chartIntervalBreakdown)?.label}</FilterButton>
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={options} className="min-w-32" optionClassName="-my-0.5" />
      </Select.Root>
    </div>
  );
};
