import React, { useCallback } from 'react';
import { FiPercent, FiHash } from 'react-icons/fi';
import { updateChartComparatorSelector } from 'Slices/filter';
import { IconToggle } from 'src/components/ToggleGroup';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { IChartComparator } from 'src/types/Filter';
import { CHART_COMPARATOR_TYPES } from 'Utils/enums/ChartComparatorTypes';
const availableComparatorOptions = [
  {
    label: FiPercent,
    value: CHART_COMPARATOR_TYPES.PERCENT_TYPE,
    'data-tooltip-content': '% Change',
    'data-tooltip-id': 'tooltip',
  },
  {
    label: FiHash,
    value: CHART_COMPARATOR_TYPES.ABSOLUTE_TYPE,
    'data-tooltip-content': 'Absolute Change',
    'data-tooltip-id': 'tooltip',
  },
];

export const ChangeTypeToggle = () => {
  const dispatch = useAppDispatch();
  const onComparatorSelect = useCallback((value: IChartComparator) => dispatch(updateChartComparatorSelector(value)), []);
  const comparator = useAppSelector((state) => state.filter.chartComparator);
  return (
    <IconToggle
      options={availableComparatorOptions}
      onValueChange={onComparatorSelect}
      optionClassName="px-4 py-2 bg-gray-650 hover:bg-gray-1000 radix-state-on:bg-indigo-50 radix-state-on:text-indigo-600 radix-state-on:ring-1 radix-state-on:ring-indigo-150 first:rounded-l-xl last:rounded-r-xl"
      aria-label="Chart comparator"
      className="gap-px rounded-xl border"
      value={comparator}
    />
  );
};
